import React, { useState, useEffect } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Register all necessary components
Chart.register(...registerables);

function Push() {
  const [message, setMessage] = useState('');
  const [datetime, setDatetime] = useState('');
  const [store, setStore] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [charCount, setCharCount] = useState(105);
  const [isSuccess, setIsSuccess] = useState(false);
  const [registrationData, setRegistrationData] = useState([]);
  const [userFavSpotData, setUserFavSpotData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://eok0i0tv6t6x7p5.m.pipedream.net');
        const data = await response.json();
        if (data.success) {
          setRegistrationData(data.data.registrations);
          setUserFavSpotData(data.data.usersByFavSpot);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsDataLoading(false);
      }
    };

    fetchData();
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!store.trim()) {
      newErrors.store = 'Store selection is required.';
    }

    setError(newErrors.datetime || newErrors.store || '');
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('https://pushNotification-me6qgdhunq-uc.a.run.app', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pushText: message, pushDateTime: datetime, store }),
      });

      setIsLoading(false);

      if (response.ok) {
        setMessage('');
        setDatetime('');
        setStore(''); 
        setCharCount(105);
        setIsSuccess(true);

        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting message:", error);
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setCharCount(105 - e.target.value.length);
  };

  const handleDatetimeChange = (e) => {
    setDatetime(e.target.value);
  };

  const handleStoreChange = (e) => {
    setStore(e.target.value);
  };

  const lineChartData = {
    labels: registrationData.map(item => item._id),
    datasets: [
      {
        label: 'Registrations',
        data: registrationData.map(item => item.count),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const pieChartData = {
    labels: userFavSpotData.map(item => item._id),
    datasets: [
      {
        data: userFavSpotData.map(item => item.count),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  return (
    <div style={{ background: 'linear-gradient(-45deg, #6a11cb, #2575fc, #7b1fa2, #8e24aa)', padding: '20px' }}>
      <div style={{ maxWidth: '1000px', margin: '0 auto', backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '15px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', overflow: 'hidden' }}>
        {!isSuccess ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff', padding: '20px', borderRadius: '12px', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)', overflowY: 'auto', maxHeight: 'calc(100vh - 80px)' }}>
            <div style={{ width: '100%', maxWidth: '500px' }}>
              <h2 style={{ marginBottom: '20px', color: '#333', textAlign: 'center' }}>Send Notification</h2>
              <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <select
                  name="store"
                  value={store}
                  onChange={handleStoreChange}
                  style={{ padding: '10px', fontSize: '16px', borderRadius: '6px', border: '1px solid #ccc', backgroundColor: '#f9f9f9', color: '#333' }}
                  required
                >
                  <option value="">Select Store</option>
                  <option value="All">All</option>
                  <option value="Buck Town">Buck Town</option>
                  <option value="Broadview">Broadview</option>
                  <option value="StLouis">St. Louis</option>
                  <option value="Hoboken">Hoboken</option>
                </select>

                <textarea
                  name="message"
                  value={message}
                  onChange={handleMessageChange}
                  style={{ padding: '10px', fontSize: '16px', resize: 'vertical', borderRadius: '6px', border: '1px solid #ccc', backgroundColor: '#f9f9f9', color: '#333' }}
                  placeholder="Enter your message here..."
                  rows="5"
                  maxLength="105"
                ></textarea>
                <div style={{ fontSize: '14px', color: '#555', textAlign: 'center' }}>
                  {charCount} characters remaining
                </div>

                <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '#e9ecef', borderRadius: '6px', marginBottom: '15px' }}>
                  <h3 style={{ marginBottom: '5px', color: '#333' }}>Coming Soon</h3>
                  <p style={{ color: '#666' }}>Schedule Push Notifications</p>
                </div>

                <input
                  type="datetime-local"
                  name="datetime"
                  value={datetime}
                  onChange={handleDatetimeChange}
                  style={{ padding: '10px', fontSize: '16px', borderRadius: '6px', border: '1px solid #ccc', backgroundColor: '#f9f9f9', color: '#333' }}
                  disabled
                />

                {error && <div style={{ color: 'red', fontSize: '14px', textAlign: 'center' }}>{error}</div>}

                <button type="submit" style={{ padding: '10px', fontSize: '16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '6px', cursor: 'pointer', transition: 'background-color 0.3s' }} disabled={isLoading}>
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center', padding: '30px', backgroundColor: '#d4edda', color: '#155724', borderRadius: '12px', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
            <div style={{ fontSize: '24px', marginBottom: '10px' }}>✓</div>
            <h2>Push Notification Sent!</h2>
          </div>
        )}
        <div style={{ marginTop: '50px' }}>
          <h3 style={{ textAlign: 'center', color: '#333' }}>User Registrations Over Time</h3>
          {isDataLoading ? (
            <div style={{ textAlign: 'center', padding: '20px', color: '#333' }}>Loading Line Chart...</div>
          ) : (
            <div style={{ overflowX: 'auto' }}>
              <Line 
                data={lineChartData} 
                options={{ 
                  maintainAspectRatio: false, 
                  responsive: true,
                  scales: { 
                    x: { 
                      ticks: { maxTicksLimit: 5 },
                      grid: { display: false }
                    },
                    y: {
                      grid: { display: true }
                    }
                  },
                  plugins: {
                    legend: { display: true, position: 'top' },
                    tooltip: { enabled: true }
                  }
                }} 
              />
            </div>
          )}
          <h3 style={{ textAlign: 'center', marginTop: '60px', color: '#333' }}>Users signup from these locations</h3>
          {isDataLoading ? (
            <div style={{ textAlign: 'center', padding: '20px', color: '#333' }}>Loading Pie Chart...</div>
          ) : (
            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
              <Pie data={pieChartData} options={{ maintainAspectRatio: true }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Push;